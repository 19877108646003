import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { auth } from "../../firebase.config"; // Asigură-te că imporți corect Firebase
import CreditIcon from "../../Components/CreditIcon/CreditIcon";
import "./BuyCredits.css";

// Înlocuiește cu cheia publică Stripe
const stripePromise = loadStripe(
  "pk_test_51KjeF4DJ37fCVX4n8Nm2lZQs8b3zSJsmGYaV3fZKPwefNmntq2bRTdklWBrFb0o93FzBkgktZb9Kn4s8DYAusOVi00Nle6S72q"
);

const BuyCredits = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const makePayment = async (amount, credits) => {
    setLoading(true);
    setError(null);

    try {
      const stripe = await stripePromise;

      if (!stripe) {
        throw new Error("Stripe.js has not loaded correctly.");
      }

      const user = auth.currentUser;
      const userId = user ? user.uid : "unknown";

      const response = await fetch(
        "https://somnolentai.com/api/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount, credits, userId }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const session = await response.json();

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      setError("Failed to initiate payment. Please try again.");
      console.error("Error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="buy-credits">
      <div className="content">
        <h2 className="text-2">Cumpără Credite</h2>
        <div className="prices">
          <div className="price" onClick={() => makePayment(2500, 1)}>
            <CreditIcon /> 1 credit: 25 RON
          </div>
          <div className="price" onClick={() => makePayment(4000, 2)}>
            <CreditIcon /> 2 credite: 40 RON
          </div>
          <div className="price" onClick={() => makePayment(5000, 3)}>
            <CreditIcon /> 3 credite: 50 RON
          </div>
        </div>
      </div>
      {loading && <p>Loading...</p>}
      {error && <p className="error-message">{error}</p>}
    </section>
  );
};

export default BuyCredits;
