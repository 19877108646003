import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { doc, updateDoc, increment } from "firebase/firestore";
import { db } from "../../firebase.config";

import "./SuccessPage.css";
import Btn from "../../Components/Btn/Btn";

const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userId = query.get("user_id");
    const credits = parseInt(query.get("credits"), 10) / 2;

    if (userId && credits) {
      const updateCredits = async () => {
        try {
          const userDocRef = doc(db, "users", userId);
          await updateDoc(userDocRef, {
            credits: increment(credits),
          });
          console.log("User credits updated successfully.");
        } catch (error) {
          console.error("Error updating user credits:", error);
        } finally {
          navigate("/success"); // Poți schimba "/profile" cu altă pagină dacă dorești
        }
      };

      updateCredits();
    }
  }, [location]);

  return (
    <section className="success">
      <div className="content">
        <h2 className="text-2">Plata este confirmata!</h2>
        <p className="p-1">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore,
          repellat?
        </p>
        <div className="btns">
          <Btn variant="primary">Genereaza un vis</Btn>
          <Btn variant="secondary">Contul tau</Btn>
        </div>
        <img
          src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1724250923/mobile-payment_n4wtfa.png"
          alt="success img"
        />
      </div>
    </section>
  );
};

export default SuccessPage;
