import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../firebase.config";
import { onAuthStateChanged } from "firebase/auth";
import Btn from "../../Components/Btn/Btn";
import { toast, ToastContainer } from "react-toastify";
import "./Profile.css";

const Profile = () => {
  const navigate = useNavigate(); // Hook pentru navigare
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
        // Redirecționare cu întârziere pentru a permite mesajelor Toastify să fie afișate
        setTimeout(() => navigate("/login"), 2000); // 2 secunde întârziere
      }
    });

    return () => unsubscribe(); // Curăță abonamentul la demontare
  }, [navigate]);

  const handleSignOut = () => {
    auth.signOut();
    toast.error("Ai ieșit din cont!");
    // Folosește toast.promise pentru a asigura că redirecționarea se face după ce Toastify a afișat mesajul
    toast
      .promise(
        new Promise((resolve) => {
          setTimeout(resolve, 2000); // Întârziere de 2 secunde pentru a permite Toastify să afișeze mesajul
        }),
        {
          pending: "Se închide contul...",
          success: "Ieșit din cont!",
          error: "Eroare la ieșirea din cont!",
        }
      )
      .then(() => {
        navigate("/login"); // Redirecționare după ce Toastify a afișat mesajul
      });
  };

  return (
    <section className="profile">
      <div className="content">
        <h2 className="text-2">{user?.displayName}, contul tău:</h2>
        <h2 className="p-2">Credite: 99</h2>
        <div className="btns">
          <Link to="/buy-credits">
            <div>
              <Btn variant="primary">Cumpără credite</Btn>
            </div>
          </Link>

          <div onClick={handleSignOut}>
            <Btn variant="danger">Ieși din cont</Btn>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={3000} />
    </section>
  );
};

export default Profile;
