import React from "react";
import "./Home.css";
import Btn from "../../Components/Btn/Btn";

const Home = () => {
  return (
    <>
      <section className="home">
        <div className="content">
          <h1 className="text-1">
            Află secretele <br /> viselor tale prin <br />{" "}
            <span className="underlined-gradient">Inteligenta Artificiala</span>
          </h1>
          <p className="p-1">
            Folosește Inteligența Artificială pentru a interpreta semnificațiile
            viselor tale.
          </p>
          <div className="btns">
            <Btn variant="primary">Incerca Acum</Btn>
            <Btn variant="secondary">Cum functioneaza?</Btn>
          </div>
        </div>
      </section>
      <section className="how-it-works">
        <div className="content">
          <h2 className="text-1">
            <span className="underlined-gradient">Cum functioneaza?</span>
          </h2>
          <div className="mockups">
            <div className="mockup">
              <h3 className="text-3">Pasul 1</h3>
              <p className="p-1">Acceseaza contul tau</p>
              <img
                src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1724233003/iPhone_12_Pro_rlwz9e.webp"
                alt="mockup"
              />
            </div>
            <div className="mockup">
              <h3 className="text-3">Pasul 2</h3>
              <p className="p-1">Acceseaza contul tau</p>
              <img
                src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1724233003/iPhone_12_Pro_rlwz9e.webp"
                alt="mockup"
              />
            </div>
            <div className="mockup">
              <h3 className="text-3">Pasul 3</h3>
              <p className="p-1">Acceseaza contul tau</p>
              <img
                src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1724233003/iPhone_12_Pro_rlwz9e.webp"
                alt="mockup"
              />
            </div>
            <div className="mockup">
              <h3 className="text-3">Pasul 4</h3>
              <p className="p-1">Acceseaza contul tau</p>
              <img
                src="https://res.cloudinary.com/dsqwnuyiw/image/upload/v1724233003/iPhone_12_Pro_rlwz9e.webp"
                alt="mockup"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
